import axios from 'axios';
import { RegalosActivity, RegalosActivityResult } from '../types/activity';

const APIURL = process.env.REACT_APP_API_HOST;

interface WalletWithRange {
  id: string;
  page?: number;
  pageSize?: number;
  startDate?: Date;
  endDate?: Date;
}

const WalletService = {
  async GetWallets() {
    return axios.get(`${APIURL}/Wallet/GetWallets`).then((res) => {
      return res.data.sort((a: any, b: any) => {
        if (a.isPrimary && !b.isPrimary) {
          return -1;
        }
        if (!a.isPrimary && b.isPrimary) {
          return 1;
        }
        return 0;
      });
    });
  },
  async GetWalletRegalos({ id, page, pageSize, startDate, endDate }: WalletWithRange) {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', startDate.toISOString());
    }
    if (endDate) {
      params.append('endDate', endDate.toISOString());
    }
    if (page) {
      params.append('page', page.toString());
    }
    if (pageSize) {
      params.append('pageSize', pageSize.toString());
    }

    const url = `${APIURL}/Wallet/GetRegalos/${id}?${params.toString()}`;
    return axios.get<RegalosActivityResult>(url);
  },
  async GetDollarsSent({ id, startDate, endDate }: WalletWithRange) {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', startDate.toISOString());
    }
    if (endDate) {
      params.append('endDate', endDate.toISOString());
    }

    return axios.get(`${APIURL}/Wallet/GetDollarsSent/${id}?${params.toString()}`);
  },
  async GetRegalosSent({ id, startDate, endDate }: WalletWithRange) {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', startDate.toISOString());
    }
    if (endDate) {
      params.append('endDate', endDate.toISOString());
    }

    return axios.get(`${APIURL}/Wallet/GetRegalosSent/${id}?${params.toString()}`);
  },
  async Balance(id: number) {
    return axios.get(`${APIURL}/Wallet/Balance/` + id);
  },
  async CloseActivityAndBalanceWallet(activityId: number, walletId: number){
    return axios.post<RegalosActivity>(`${APIURL}/Wallet/CloseActivityAndBalance?activityId=` + activityId + `&walletId=` + walletId);
  },
  async SendToMember(body: {
    BudgetId: number;
    MemberId: number;
    Amount: number;
    Reason: string;
    Message: string;
    TagId: number;
    IsPublic: boolean;
  }) {
    return axios.post(`${APIURL}/Wallet/SendToMember`, body);
  },
  async UpdateWalletDetails(
    id: string,
    body: {
      Name?: string;
      Details?: string;
      IsActive?: boolean;
    },
  ) {
    return axios.put(`${APIURL}/Wallet/Update/${id}`, body);
  },
  async CreateWallet(body: {
    name: string;
    details: string;
    amount: number;
    managers: number[];
    fromWalletId: number;
  }) {
    return axios.post(`${APIURL}/Wallet/Create`, {
      ...body,
      amount: body.amount * 100,
    });
  },
  async AddMember(body: { WalletId: string; MemberId: number }) {
    return axios.put(`${APIURL}/Wallet/AddMember/${body.WalletId}/${body.MemberId}`, body);
  },
  async RemoveMember(body: { WalletId: string; MemberId: number }) {
    return axios.delete(`${APIURL}/Wallet/RemoveMember/${body.WalletId}/${body.MemberId}`);
  },
  async PostTopUp(params: { FromWalletId: number; ToWalletId: number; Amount: number }) {
    return axios.post(`${APIURL}/Wallet/PostTopUp/${params.FromWalletId}/${params.ToWalletId}`, {
      Amount: params.Amount * 100,
    });
  },
  async SearchActivity(params: { walletId: number; query: string }) {
    return axios.post<RegalosActivityResult>(
      `${APIURL}/Wallet/Search/${params.walletId}?searchTerm=${params.query}`,
    );
  },
};

export default WalletService;
