import { API } from 'aws-amplify';
import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

export interface AnnouncementAdminDto {
  id: number;
  title?: string;
  body?: string;
  isActive?: boolean;
  isRead?: boolean;
  hidePicture?: boolean;
  whenLive: Date | null; // Date or null
  whenExpires: Date | null; // Date or null
  videoLink?: string;
  sendEmployers?: string; // comma-separated list of employers
  link?: string; // link in app or internal page reference
  linkText?: string; // text to display for the link
  picture?: string;
  status: number;
  canUpdateEmployers?: boolean;
  targetType?: number;
  targetIds?: string;
  targetTypeName: string;
  targetDisplay: string;
}

export interface AnnouncementListResult {
  totalCount: number;
  announcements: AnnouncementAdminDto[];
}

const AnnouncementAdminService = {
  async GetAnnouncementsList(params?: string) {
    let url = `${APIURL}/AnnouncementAdmin/GetAnnouncementsList`;
    if (params) {
      url += `?${params}`;
    }
    return axios.get<AnnouncementListResult>(url);
  },
  async GetAnnouncement(id: number) {
    return axios.get<AnnouncementAdminDto>(`${APIURL}/AnnouncmentAdmin/GetAnnouncementById/${id}`);
  },
  async GetNewAnnouncement() {
    return axios.get<AnnouncementAdminDto>(`${APIURL}/AnnouncementAdmin/GetNewAnnouncement`);
  },
  async AddAnnouncement(announcement: AnnouncementAdminDto) {
    return axios.post<AnnouncementAdminDto>(`${APIURL}/AnnouncementAdmin/CreateAnnouncement`, announcement);
  },
  async ApproveAnnouncements(ids: number[]) {
    return axios.post(`${APIURL}/AnnouncementAdmin/ApproveAnnouncements`, ids);
  },
  async UploadAnnouncementPicture(file: FormData) {
    return axios.post<string>(`${APIURL}/AnnouncementAdmin/PostAnnouncementPicture`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async UpdateAnnouncement(announcement: AnnouncementAdminDto) {
    return axios.put<AnnouncementAdminDto>(
      `${APIURL}/AnnouncementAdmin/UpdateAnnouncement/${announcement.id}`,
      announcement,
    );
  },
  async DeleteAnnouncement(id: number) {
    return axios.delete(`${APIURL}/AnnouncementAdmin/DeleteAnnouncement/${id}`);
  },
};

export default AnnouncementAdminService;
