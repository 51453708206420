import React, { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  TextareaChangeEventDetail,
  TextareaCustomEvent,
} from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import './Activity.css';
import { useRecoilState } from 'recoil';
import { selectedWalletState } from './atoms';
import WalletService from '../../Shared/services/WalletService';
import { RegalosActivity } from '../../Shared/types/activity';
import { BudgetDebit, SpotBonus } from './ActivityRow';
import uniqBy from 'lodash/uniqBy';
import WalletView from './Wallet';

const _startDate = new Date().setFullYear(new Date().getFullYear() - 1);

function WalletActivity() {
  const [selectedWallet, setSelectedWallet] = useRecoilState(selectedWalletState);
  const [startDate, setStartDate] = useState(new Date(_startDate).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [status, setStatus] = useState<'active' | 'inactive'>();
  const [editDescription, setEditDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState('');
  const [dollarsSent, setDollarsSent] = useState(0);
  const [activity, setActivity] = useState<RegalosActivity[]>([]);
  const [activityPage, setActivityPage] = useState(0);
  const [maxActivityPage, setMaxActivityPage] = useState(1);
  const [regalosSent, setRegalosSent] = useState(0);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [searchValue] = useState('');
  const [searchResults, setSearchResults] = useState<RegalosActivity[] | null>(null);
  const observerTarget = useRef(null);

  useEffect(() => setStatus(selectedWallet?.isActive ? 'active' : 'inactive'), [selectedWallet]);

  useEffect(() => {
    if (!selectedWallet) return;

    setLoadingActivity(true);

    WalletService.GetWalletRegalos({
      id: selectedWallet.id,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      page: activityPage,
    })
      .then((activities) => {
        setMaxActivityPage(Math.ceil(activities.data.totalCount / 10));
        setActivity(activities.data.regalosList);
      })
      .finally(() => setLoadingActivity(false));

    WalletService.GetDollarsSent({
      id: selectedWallet.id,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }).then((dollarsSent) => {
      setDollarsSent(dollarsSent.data);
    });

    WalletService.GetRegalosSent({
      id: selectedWallet.id,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }).then((regalosSent) => {
      setRegalosSent(regalosSent.data);
    });
  }, [selectedWallet?.id, startDate, endDate]);

  const handleStartDateChange = (e: any) => {
    setStartDate(e.detail.value);
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e.detail.value);
  };

  const handleDescriptionChange = (e: TextareaCustomEvent<TextareaChangeEventDetail>) => {
    if (selectedWallet?.id) {
      WalletService.UpdateWalletDetails(selectedWallet.id, {
        Details: e.detail.value ?? '',
      }).then((res) => setSelectedWallet(res.data));
      setEditedDescription(e.detail.value || '');
    }
  };

  const handleStatusChange = (e: any) => {
    if (selectedWallet?.id) {
      WalletService.UpdateWalletDetails(selectedWallet.id, {
        IsActive: e.detail.value === 'active',
      });
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      setSearchResults(null);
      return;
    }
    WalletService.SearchActivity({
      walletId: parseInt(selectedWallet?.id ?? '-1'),
      query: searchValue,
    }).then((results) => {
      setSearchResults(results.data.regalosList);
    });
  }, [searchValue]);

  useEffect(() => {
    console.log('activityPage', activityPage, maxActivityPage);
    if (activityPage >= maxActivityPage) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log(activityPage);
          setLoadingActivity(true);
          WalletService.GetWalletRegalos({
            id: selectedWallet?.id as string,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            page: activityPage + 1,
          })
            .then((newActivities) => {
              setMaxActivityPage(Math.ceil(newActivities.data.totalCount / 10));
              setActivityPage((page) => page + 1);
              setActivity((act) => uniqBy([...act, ...newActivities.data.regalosList], 'id'));
            })
            .finally(() => setLoadingActivity(false));
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, activityPage]);

  function handleCloseAndBalance(id: number) {
    const walletId = parseInt(selectedWallet?.id ?? "-1");
    setLoadingActivity(true);
    WalletService.CloseActivityAndBalanceWallet(id, walletId).then((resp) => {
      const searchActivityIndex = searchResults?.findIndex(x => x.id === id) ?? -1;
      if(searchActivityIndex === -1){
        const activityIndex = activity?.findIndex(x => x.id === id) ?? -1;
        if(activityIndex === -1) return; //kick out if we didn't find the activity at all
        const newActivities = [...activity];
        newActivities[activityIndex] = resp.data;
        setActivity(newActivities);
      } else if ( searchResults !== null ) {
        const newSearchResults = [...searchResults];
        newSearchResults[searchActivityIndex] = resp.data;
        setSearchResults(newSearchResults);
      }

    });
  }

  return (
    <div>
      <div
        style={{
          marginTop: 20,
          padding: 20,
          background: 'linear-gradient(180deg, #D8EAE5 0%, #FFF 97%)',
          border: '1px solid lightgrey',
          borderRadius: 10,
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <div>
            <h5>${((selectedWallet?.balance ?? 0) / 100).toFixed(2)}</h5>
            <p
              style={{
                fontSize: 12,
              }}
            >
              Budget Available
            </p>
          </div>
          <div>
            <IonList className='ion-no-padding transparent'>
              <IonItem
                style={{
                  '--border-radius': '8px',
                  '--border-width': '0.5px',
                }}
              >
                <IonSelect
                  value={status}
                  onIonChange={handleStatusChange}
                  aria-label='status'
                  placeholder='Select fruit'
                >
                  <IonSelectOption value='active'>Active</IonSelectOption>
                  <IonSelectOption value='inactive'>Inactive</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
            <p
              style={{
                fontSize: 12,
              }}
            >
              Status
            </p>
          </div>
          <div>
            <h5>{regalosSent}</h5>
            <p
              style={{
                fontSize: 12,
              }}
            >
              Regalos Sent
            </p>
          </div>
          <div>
            <h5>${(dollarsSent / 100).toFixed(2)}</h5>
            <p
              style={{
                fontSize: 12,
              }}
            >
              Dollars Sent
            </p>
          </div>
        </div>
        {editDescription ? (
          <IonTextarea
            placeholder='Type something here'
            value={editedDescription}
            onIonChange={handleDescriptionChange}
            style={{
              border: '1px solid lightgrey',
              borderRadius: 10,
              padding: 4,
              fontSize: 12,
            }}
          />
        ) : (
          <p
            style={{
              marginTop: 12,
              fontSize: 12,
            }}
          >
            {selectedWallet?.details}
          </p>
        )}
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          {editDescription ? (
            <IonButton
              fill='clear'
              style={{
                color: '#03989E',
                fontWeight: 400,
                textTransform: 'none',
                fontSize: 12,
                padding: 6,
                margin: 0,
              }}
              onClick={() => {
                setEditDescription(false);
              }}
            >
              Save Description
            </IonButton>
          ) : (
            <IonButton
              fill='clear'
              style={{
                color: '#03989E',
                fontWeight: 400,
                textTransform: 'none',
                fontSize: 12,
                padding: 6,
                margin: 0,
              }}
              onClick={() => {
                setEditDescription(true);
                selectedWallet && setEditedDescription(selectedWallet.details);
              }}
            >
              Edit Description
            </IonButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {/* <IonSearchbar
            className="mw-input"
            debounce={300}
            value={searchValue}
            onIonInput={(e) => {
              setSearchValue(e.target.value!);
            }}
            style={{
              width: 240,
              border: "1px solid #EBEBEB",
              backgroundColor: "#FFF",
              padding: 6,
              borderRadius: 10,
              marginTop: 5,
              paddingTop: 2,
              paddingBottom: 2,
              "--background": "transparent",
              "--box-shadow": "none",
            }}
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 8,
              justifyContent: 'space-between',
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              marginTop: 5,
              padding: 4,
            }}
          >
            <IonDatetimeButton datetime='startDate' />
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id='startDate'
                presentation='date'
                value={startDate}
                max={endDate || new Date().toISOString().split('T')[0]}
                onIonChange={handleStartDateChange}
              />
            </IonModal>
            <IonIcon
              color='primary'
              icon={arrowForward}
              style={{
                top: 12,
                left: 12,
              }}
            />
            <IonDatetimeButton datetime='endDate' />
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id='endDate'
                presentation='date'
                value={endDate}
                min={startDate}
                max={new Date().toISOString().split('T')[0]}
                onIonChange={handleEndDateChange}
              />
            </IonModal>
          </div>
        </div>
      </div>
      <div>
        {(searchResults ?? activity).map((activity) => {
          if (activity.receiverName) {
            return <SpotBonus key={activity.id} activity={activity} />;
          } else {
            return <BudgetDebit key={activity.id} activity={activity} handleCloseAndBalance={handleCloseAndBalance} />;
          }
        })}
        {loadingActivity ? (
          <IonSpinner
            style={{
              marginTop: 24,
              marginLeft: 'calc(50% - 14px)',
            }}
          />
        ) : (
          <>
            <div ref={observerTarget}></div>
            <p
              style={{
                textAlign: 'center',
                color: 'grey',
                fontSize: 12,
                marginTop: 10,
              }}
            >
              No more activity.
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default WalletActivity;
