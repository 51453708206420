import React from 'react';
import { RegalosActivity } from '../../Shared/types/activity';
import ImageService from '../../Shared/services/ImageService';
import { IonButton, IonIcon } from '@ionic/react';
import { checkbox } from 'ionicons/icons';

export function SpotBonus({ activity }: { activity: RegalosActivity }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        borderBottom: '1px solid lightgrey',
      }}
    >
      <div
        className='mx-border'
        style={{
          margin: 10,
          backgroundImage: `url(${
            activity.senderPicture
              ? ImageService.Url(activity.senderPicture)
              : '/assets/member_placeholder.png'
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: 40,
          width: 40,
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 10,
          }}
        >
          <span>
            <h6 style={{ display: 'inline' }}>{activity.senderName ?? 'N/A'}</h6> to{' '}
            <h6 style={{ display: 'inline' }}>{activity.receiverName ?? 'N/A'}</h6>
            <p
              style={{
                color: 'grey',
                fontSize: 10,
                marginBottom: 0,
              }}
            >
              {activity.created && new Date(activity.created).toLocaleString()}
            </p>
          </span>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <h6>{activity.title}</h6>
            <p
              style={{
                marginBottom: 0,
              }}
            >
              {activity.amount}
            </p>
          </div>
        </div>
        <p
          style={{
            fontSize: 12,
            marginBottom: 0,
          }}
        >
          {activity.message}
        </p>
      </div>
    </div>
  );
}

export function BudgetDebit({ activity, handleCloseAndBalance }: { activity: RegalosActivity, handleCloseAndBalance: (id: number) => void  }) {
  return (
    <div
      key={activity.id}
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        borderBottom: '1px solid lightgrey',
      }}
    >
      <div
        className='mx-border'
        style={{
          margin: 10,
          backgroundImage: `url(${activity.senderPicture ?? '/assets/member_placeholder.png'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: 40,
          width: 40,
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 10,
          }}
        >
          <span>
            <h6 style={{ display: 'inline' }}>{activity.title ?? 'N/A'}</h6>{' '}
            <p
              style={{
                color: 'grey',
                fontSize: 10,
                marginBottom: 0,
              }}
            >
              {activity.created && new Date(activity.created).toLocaleString()}
            </p>
          </span>
          <div
            style={{
              textAlign: 'right',
              width: 150,
            }}
          >
            <h6>{activity.activityType}</h6>
            <p
              style={{
                marginBottom: 0,
              }}
            >
              {activity.amount}
            </p>
            {activity.showCloseAndBalanceButton && 
                <IonButton
                  onClick={() => handleCloseAndBalance(activity.id)}
                  color="dark"
                  size="small"
                >
                  Close And Balance Wallet
                </IonButton>
            }
          </div>
        </div>
        <p
          style={{
            fontSize: 12,
            marginBottom: 0,
          }}
        >
          {activity.message}
        </p>
      </div>
    </div>
  );
}
