import { IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ImageService from './services/ImageService';
import { eyeOff } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { userState } from './services/auth';
import { useRecoilState } from 'recoil';
import FeatureFlags from './services/FeatureFlagService';

interface Props {
  employer: any;
}

export default function SideBar(props: Props) {
  const [user] = useRecoilState<any>(userState);
  const [selected, setSelected] = React.useState<string>('overview');
  const [anniversaryAvailable, setAnniversaryAvailable] = useState(false);
  const [announcmentAdminAvailable, setAnnouncementAdminAvailable] = useState(false);

  useEffect(() => {
    setSelected(window.location.pathname.substring(1));
    console.log(window.location.pathname.substring(1));
  }, [window.location.pathname]);

  useEffect(() => {
    const checkFlags = async () => {
      const featureFlags = await FeatureFlags.featuresAvailable();
      const anniversaryAvailable = featureFlags.some((ff) => ff.name == 'AnniversaryUI');
      setAnniversaryAvailable(anniversaryAvailable);
      const announcmentAdminAvailable = featureFlags.some((ff) => ff.name == 'AnnouncementAdminUI');
      setAnnouncementAdminAvailable(announcmentAdminAvailable);
    };

    checkFlags();
  }, []);

  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        width: 280,
        backgroundColor: '#f5f5f5',
        borderRight: '3px solid black',
        backgroundImage: 'linear-gradient(#fcfcfc, #fcfcfc, #EAF3F1)',
      }}
    >
      <div
        style={{
          marginTop: 0,
          height: 140,
          width: '100%',
          padding: '10px 30px 10px 20px',
        }}
      >
        {' '}
        <div
          onClick={() => {
            history.push('/');
          }}
          style={{
            height: 140,
            padding: 20,
            backgroundImage: 'url(' + ImageService.Url(props.employer?.logo) + ')',
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
      </div>
      <div
        style={{
          height: 100,
          width: '100%',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            paddingLeft: 20,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {/* //Temporarily removing "overview" in max-134
            <div
            className={
              "sidebar-top-level " +
              (selected == "overview" && "sidebar-selected")
            }
            onClick={() => {
              setSelected('overview');
              history.push('/');
            }}
          >
            Overview
          </div> */}

          <div
            className={
              'sidebar-top-level ' +
              ((selected == 'team' || selected == 'wallets') && 'sidebar-selected')
            }
            onClick={() => {
              setSelected('team');
              history.push('/team');
            }}
          >
            Team
          </div>
          <div
            className={'sidebar-sub-level ' + (selected == 'team' && 'sidebar-selected')}
            onClick={() => {
              setSelected('team');
              history.push('/team');
            }}
          >
            Manage Team
          </div>

          <div
            className={'sidebar-sub-level ' + (selected == 'wallets' && 'sidebar-selected')}
            onClick={() => {
              setSelected('wallets');
              history.push('/wallets');
            }}
          >
            Manage Wallets
          </div>

          {anniversaryAvailable && (
            <div
              className={'sidebar-sub-level ' + (selected == 'anniversaries' && 'sidebar-selected')}
              onClick={() => {
                setSelected('anniversaries');
                history.push('/anniversaries');
              }}
            >
              Manage Anniversaries
            </div>
          )}

          {announcmentAdminAvailable && (
            <div
              className={'sidebar-sub-level ' + (selected == 'announcements' && 'sidebar-selected')}
              onClick={() => {
                setSelected('announcements');
                history.push('/announcements');
              }}
            >
              Manage Announcements
            </div>
          )}

          <div
            className={
              'sidebar-top-level ' +
              ((selected == 'benefits' || selected == 'marketplace') && 'sidebar-selected')
            }
            onClick={() => {
              setSelected('benefits');
              history.push('/benefits');
            }}
          >
            Benefits+
          </div>
          <div
            className={'sidebar-sub-level ' + (selected == 'benefits' && 'sidebar-selected')}
            onClick={() => {
              history.push('/benefits');
            }}
          >
            Benefits
          </div>
          <div
            className={'sidebar-sub-level ' + (selected == 'marketplace' && 'sidebar-selected')}
            onClick={() => {
              setSelected('marketplace');
              history.push('/marketplace');
            }}
          >
            Marketplace
          </div>
          {/* <div
            className="sidebar-sub-level"
            onClick={() => {
              history.push("/announcements");
            }}
          >
            Announcements
          </div> */}
          <div
            className={
              'sidebar-top-level ' +
              ((selected == 'settings/usage' ||
                selected == 'settings/financial' ||
                selected == 'settings/exits') &&
                'sidebar-selected')
            }
            onClick={() => {
              setSelected('settings/usage');
              history.push('/settings/usage');
            }}
          >
            Reports
          </div>
          <div
            className={'sidebar-sub-level ' + (selected == 'settings/usage' && 'sidebar-selected')}
            onClick={() => {
              setSelected('settings/usage');
              history.push('/settings/usage');
            }}
          >
            Usage
          </div>
          <div
            className={
              'sidebar-sub-level ' + (selected == 'settings/financial' && 'sidebar-selected')
            }
            onClick={() => {
              setSelected('settings/financial');
              history.push('/settings/financial');
            }}
          >
            Financial
          </div>
          <div
            className={'sidebar-sub-level ' + (selected == 'settings/exits' && 'sidebar-selected')}
            onClick={() => {
              setSelected('settings/exits');
              history.push('/settings/exits');
            }}
          >
            Exits
          </div>
          {user?.currentMemberEmployerId == 1 && (
            <div
              className={
                'sidebar-top-level ' + (selected == 'settings/maxwell' && 'sidebar-selected')
              }
              onClick={() => {
                setSelected('settings/maxwell');
                history.push('/settings/maxwell');
              }}
            >
              Maxwell <IonIcon style={{ marginLeft: 4, fontSize: 14 }} icon={eyeOff} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: 20,
        }}
      >
        <a
          className={'sidebar-top-level ' + (selected == 'faq' && 'sidebar-selected')}
          onClick={() => {
            setSelected('faq');
            window.open('/faq');
          }}
        >
          View FAQs
        </a>
      </div>
    </div>
  );
}
