import { IonIcon } from '@ionic/react';
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { checkmarkCircle } from 'ionicons/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { bulkModalAtom, confirmApproveAtom, selectedRowsAtom, teamQueryAtom } from './atoms';
import { ToolbarButton } from './shared';

export function Toolbar() {
  const setShowBulkModal = useSetRecoilState(bulkModalAtom);
  const setConfirmApprove = useSetRecoilState(confirmApproveAtom);
  const selectedRows = useRecoilValue(selectedRowsAtom);  
  const [teamQuery, _] = useRecoilState(teamQueryAtom);
  const isExitedTab = (teamQuery.onboardingStatus === 'exited');

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, paddingLeft: 8 }}>
        <GridToolbarContainer>
          <GridToolbarExport />
          <GridToolbarFilterButton />
          <ToolbarButton
            label='Remove'
            icon='./assets/person-dash.svg'
            onClick={() => {
              if (selectedRows && selectedRows.length > 0) {
                setShowBulkModal('removeModal');
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || isExitedTab}
          />
          <ToolbarButton
            label='Edit'
            icon='./assets/pencil.svg'
            onClick={() => {
              if (selectedRows && selectedRows.length > 0) {
                setShowBulkModal('editModal');
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || isExitedTab}
          />
          <ToolbarButton
            label='Approve'
            onClick={() => {
              if (selectedRows && selectedRows.length > 0) {
                setConfirmApprove(true);
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || isExitedTab}
          >
            <IonIcon
              src={checkmarkCircle}
              style={{
                width: 20,
                height: 20,
              }}
            />
          </ToolbarButton>
          <ToolbarButton
            label='Spot Bonus'
            icon='./assets/cash.svg'
            onClick={() => {
              if (selectedRows && selectedRows.length > 0) {
                setShowBulkModal('spotModal');
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || isExitedTab}
          />
          <ToolbarButton
            label='Force Balance'
            icon='./assets/md-refresh-circle.svg'
            onClick={() => {
              if(selectedRows && selectedRows.length > 0){
                setShowBulkModal('forceBalance');
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || isExitedTab}
          />
          <ToolbarButton
            label='Reactivate Member'
            icon='./assets/person-outline.svg'
            onClick={() => {
              if(selectedRows && selectedRows.length > 0){
                setShowBulkModal('reactivateModal');
              }
            }}
            disabled={(selectedRows && selectedRows.length === 0) || !isExitedTab}
          />
        </GridToolbarContainer>
      </div>
    </div>
  );
}
